import { EnumLiteralsOf } from "./helperTypes";

export const YesorNo = Object.freeze({
  YES: "Yes" as const,
  NO: "No" as const,
});
export type POSStores = EnumLiteralsOf<typeof POSStores>;

export const POSStores = Object.freeze({
  DEFAULT: "DEFAULT" as const,
  PRODUCT: "PRODUCT" as const,
  CATEGORY: "CATEGORY" as const,
  // SALEDETAILS: 'SALEDETAILS' as const,
});

export const Privileges = Object.freeze({
  User: "User" as const,
  CategoryEdit: "Category_Edit" as const,
  ProductDDelete: "Product_Delete" as const,
  Sales: "Sales" as const,
  CategoryView: "Category_View" as const,
  DashboardAdminZone: "Dashboard_Admin_Zone" as const,
  ProductAAdd: "Product_Add" as const,
  ProductEdit: "Product_Edit" as const,
  ProductView: "Product_View" as const,
  CategoryDelete: "Category_Delete" as const,
  Report: "Report" as const,
  CategoryAdd: "Category_Add" as const,
  Dashboard: "Dashboard" as const,
  Inventory: "Inventory" as const,
  Purchase: "Purchase" as const,
  BillPayments: "BillPayments" as const,
});

export const ServicesStatuses = Object.freeze({
  PENDING: 0 as const,
  PAYMENT_PENDING: 1 as const,
  VERIFIED: 2 as const,
  CANCEL: 3 as const,
  PAID: 4 as const,
  APPROVED: 10 as const,
  REVIEWED: 11 as const
});

export const postType = Object.freeze({
  Matrimonial: 1 as const,
  Service: 2 as const,
  Suprise: 3 as const,
});

export const SurpricePackage = Object.freeze({
  Silver: 1 as const,
  Gold: 2 as const,
  Platinum: 3 as const,
});

export const CastType = Object.freeze({
  WWWW : 0 as const,
  XXXX: 3 as const,
  YYYY: 4 as const,
  ZZZZ: 5 as const,
  SSSS: 6 as const,
});

export const OccupationType = Object.freeze({
  Teacher: 7 as const,
  Labour: 8 as const,
  Manager: 9 as const,
});

export const CountryType = Object.freeze({
  SriLanka: 10 as const,
  India: 11 as const,
});

export const GenderType = Object.freeze({
  Male: 1 as const,
  Female: 2 as const
});

export const ReligionType = Object.freeze({
  Hindu: 13 as const,
  RC: 14 as const,
  NonRC: 15 as const
});

export const MaritalStatusType = Object.freeze({
  Single: 16 as const,
  Divorce: 17 as const,
  Widowes: 18 as const,
  Separete: 19 as const
});

export const DistrictType = Object.freeze({
  Jaffna: 20 as const,
  Mullaitivu: 21 as const,
  Kilinochchi: 22 as const,
  Mannar: 23 as const,
  Batticalo: 24 as const
});

export const CommunityType = Object.freeze({
  JaffnaTmail: 25 as const,
  IndianTamil: 26 as const,
  EastTamil: 27 as const
});

export const BloodGroupType = Object.freeze({
  APositive: 28 as const,
  ANegative: 29 as const,
  BPositive: 30 as const,
  BNegative: 31 as const,
  OPositive: 32 as const,
  ONegative: 33 as const,
  ABPositive: 34 as const,
  ABNegative: 35 as const
});

export const FoodHabitType = Object.freeze({
  Veg: 36 as const,
  NonVeg: 37 as const
});

export const NakshatraType = Object.freeze({
  Chitra: 38 as const,
  Mrigashira: 39 as const,
  Punarvasu: 40 as const,
  Swati: 41 as const,
  Krittika: 42 as const
});

export const RasiType = Object.freeze({
  Aries: 43 as const,
  Taurus: 44 as const,
  Gemini: 45 as const,
  Cancer: 46 as const,
  Leo: 47 as const,
  Virgo: 48 as const
});

export const MotherTongueType = Object.freeze({
  Tamil: 49 as const,
  Hindi: 50 as const
});

export const ServicesCategoryType = Object.freeze({
  Electrical: 1 as const,
  Cleaning: 3 as const,
  Painting: 7 as const,
  Repairing: 8 as const,
  Landscaping: 9 as const,
  Roofing: 10 as const,
  Flooring: 11 as const,
  Laundry: 12 as const
});

export const CurrencyFormatType = Object.freeze({
  LKR: 51 as const
});

export const JobStatusType = Object.freeze({
  Permenat: 52 as const,
  Private: 53 as const,
  Goverment: 54 as const
});

export const IsProvidingType = Object.freeze({
  Yes: "1" as const,
  No: "0" as const,
});