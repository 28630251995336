import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useApp = () => {
  const location = useLocation();

  const currentMenuName = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);
  return { currentMenuName };
};

export default useApp;
