import { palette } from "./palette";

export const ArrowIconStyle = {
  float: 'right',
  cursor: 'pointer',
  textAlign: 'center',
  fontSize: '14px',
  marginRight: '20px',
  color: palette.action,
};

export const CardSkeletonStyle = {
  width: '327px',
  height: '214px',
  alignSelf: 'center'
}
