import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../config";
import {
  IApiResponse,
  IServiceDataApiResponse,
  IServiceDetailsPayload,
  IUser,
  IWeddingHall,
} from "../core/Interface/api.interface";
import { request } from "http";

/**
 * Holds all the API callbacks
 * @returns RTK Implementation for backend
 */

export const AuritionAdminApi = createApi({
  reducerPath: "posApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("token", `${localStorage.getItem("token") || ""}`);
      return headers;
    },
  }),
  tagTypes: [
    "User",
    "UserManagement",
    "dashboard",
    "Driver",
    "user",
    "Vehicle",
    "Service",
    "Bookingservices",
    "WeddingHalls",
    "Ads",
    "Matriposts",
    "Surpriseposts",
    "Serviceposts",
    "Donations"
  ],
  endpoints: (builder) => ({
    getUser: builder.query<IApiResponse, void>({
      query: () => {
        return {
          url: "/admin/info",
          method: "GET",
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["User"],
    }),
    login: builder.mutation<IApiResponse, IUser>({
      query: (request) => {
        return {
          url: "/admin/login",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
    }),
    // list all services
    getAllService: builder.query<IApiResponse, IServiceDetailsPayload>({
      query: (request) => {
        return {
          url: "/admin/order",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["Service"],
      keepUnusedDataFor: 0,
    }),
    getChangedStatus: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/change_status",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Service"],
    }),
    getSurprise: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/services/suprise/add",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Service"],
    }),
    getServices: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/services/add",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Service"],
    }),
    getMatri: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/services/matri/add",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Service"],
    }),
    // list only matri posts
    getAllMatriPosts: builder.query<IApiResponse, IServiceDetailsPayload>({
      query: (request) => {
        return {
          url: "/admin/matriposts",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["Matriposts"],
      keepUnusedDataFor: 0,
    }),
    // update matri status
    getChangedMatriStatus: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/matri/status/update",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Matriposts"],
    }),
    // update matri post datas
    updateMatri: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/matri/update",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Matriposts"],
    }),
    // list only Surprise posts
    getAllSurprisePosts: builder.query<IApiResponse, IServiceDetailsPayload>({
      query: (request) => {
        return {
          url: "/admin/surpriseposts",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["Surpriseposts"],
      keepUnusedDataFor: 0,
    }),
     // update surprise status
     getChangedSurpriseStatus: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/surprise/status/update",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Surpriseposts"],
    }),
    // update Surprise post datas
    updateSurprise: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/surprise/update",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Surpriseposts"],
    }),
    // list only Service posts
    getAllServicePosts: builder.query<IApiResponse, IServiceDetailsPayload>({
      query: (request) => {
        return {
          url: "/admin/serviceposts",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["Serviceposts"],
      keepUnusedDataFor: 0,
    }),
    // update service status
    getChangedServiceStatus: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/service/status/update",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Serviceposts"],
    }),
    // update Service post datas
    updateService: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/service/update",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Serviceposts"],
    }),
    getBookingServices: builder.query<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/booking/service",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["Bookingservices"],
      keepUnusedDataFor: 0,
    }),
    getWeddingHalls: builder.query<IApiResponse, object>({
      query: (request) => {
        return {
          url: "/admin/weddingHalls",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["WeddingHalls"]
    }),
    getWeddingHall: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/weddingHall/add",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["WeddingHalls"],
    }),
    deleteWeddingHall: builder.mutation<IApiResponse, Object>({
      query: (id) => {
        return {
          url: `/admin/weddingHall/delete/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['WeddingHalls'],
    }),
    getAds: builder.query<IApiResponse, IServiceDetailsPayload>({
      query: (request) => {
        return {
          url: "/admin/ads",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["Ads"]
    }),
    getNewAd: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/ads/update",
          method: "POST",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Ads"],
    }),
    // List all donations
    getAllDonations: builder.query<IApiResponse, IServiceDetailsPayload>({
      query: (request) => {
        return {
          url: "/admin/donations",
          method: "POST",
          body: request,
        };
      },
      providesTags: ["Donations"],
      keepUnusedDataFor: 0,
    }),
    // Update Donation datas
    updateDonation: builder.mutation<IApiResponse, Object>({
      query: (request) => {
        return {
          url: "/admin/donation/update",
          method: "PUT",
          body: JSON.stringify(request),
        };
      },
      invalidatesTags: ["Donations"],
    }),
    // Delete donation 
    deleteDonation: builder.mutation<IApiResponse, Object>({
      query: (id) => {
        return {
          url: `/admin/donation/delete/${id}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Donations'],
    }),
  }),
});

export const {
  useGetUserQuery,
  useLoginMutation,
  useGetAllServiceQuery,
  useGetChangedStatusMutation,
  useGetSurpriseMutation,
  useGetServicesMutation,
  useGetMatriMutation,
  useGetAllMatriPostsQuery,   //for list all matri posts
  useGetChangedMatriStatusMutation, //for change matri post status
  useUpdateMatriMutation, // for update matri post datas only
  useGetAllSurprisePostsQuery,  // for list all surprise posts
  useGetChangedSurpriseStatusMutation,  //for change surprise post status
  useUpdateSurpriseMutation,  // for update surprise post datas only
  useGetAllServicePostsQuery,   // for list all service posts
  useGetChangedServiceStatusMutation, //for change service post status
  useUpdateServiceMutation, // for update service post datas only
  useGetBookingServicesQuery,
  useGetWeddingHallsQuery,
  useGetWeddingHallMutation,
  useDeleteWeddingHallMutation,
  useGetAdsQuery,
  useGetNewAdMutation,
  useGetAllDonationsQuery,
  useUpdateDonationMutation,
  useDeleteDonationMutation
} = AuritionAdminApi;
