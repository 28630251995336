import React, { useMemo } from 'react';
import { MainLayoutProps } from '../../core/Interface/layout.interface';
import { Loader } from '../../components/Loader';
import Auth from '.';
import { useGetUserQuery } from '../appApi';
import { selectEnableAuth } from './AuthSelector';
import { useSelector } from 'react-redux';

const LoginProvider = ({ children }: MainLayoutProps) => {
  const { isLoading } = useGetUserQuery();
  const authEnable =  useSelector(selectEnableAuth)
  
  const renderComponent = useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }
    return authEnable ? children : <Auth />;
  }, [isLoading, authEnable, children]);
  return <>{renderComponent}</>;
};

export default React.memo(LoginProvider);
