import React from 'react'
import { MainLayoutProps } from '../core/Interface/layout.interface';
import { motion } from 'framer-motion';
const animations = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 0 },
};
const AnimatedPage = ({ children }: MainLayoutProps) => {
  return (
    <motion.div
      animate="animate"
      exit="exit"
      initial="initial"
      variants={animations}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedPage;