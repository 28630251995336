import { Loader } from "../components/Loader";
import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import useUser from "../core/hooks/useUser";
import { Privileges } from "../core/types/commonType";

const Dashboard = React.lazy(() => import("../page/Dashboard"));
const Services = React.lazy(() => import("../page/Services"));
const BookingServices = React.lazy(() => import("../page/BookingServices"));
const WeddingHalls = React.lazy(() => import("../page/WeddingHall"));
const Ads = React.lazy(() => import("../page/Ads"));
const Matriposts = React.lazy(() => import("../page/MatrimonialPosts"));
const Surpriseposts = React.lazy(() => import("../page/SurprisePosts"));
const Serviceposts = React.lazy(() => import("../page/ServicePosts"));
const Donations = React.lazy(() => import("../page/Donation"));

/**
 * AppRoutes will load the app routes.
 * @returns
 */
const AppRoutes = () => {
  const { userHasRight } = useUser();
  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<Loader />}>{/* <Fallback /> */}</Suspense>
        }
        path="/fallback"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) && <Dashboard />}
          </Suspense>
        }
        path="/dashboard"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) && <Services />}
          </Suspense>
        }
        path="/services/past-order"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) && <Matriposts />}
          </Suspense>
        }
        path="/matriposts"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) && <Surpriseposts />}
          </Suspense>
        }
        path="/surpriseposts"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) && <Serviceposts />}
          </Suspense>
        }
        path="/serviceposts"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) && <BookingServices />}
          </Suspense>
        }
        path="/booking_services"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) && <WeddingHalls />}
          </Suspense>
        }
        path="/Wedding_halls"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) && <Ads />}
          </Suspense>
        }
        path="/ads"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) && <Donations />}
          </Suspense>
        }
        path="/donations"
      />

      {/* default if nothing matches */}
      <Route element={<Navigate replace to="/dashboard" />} path="*" />
    </Routes>
  );
};

export default AppRoutes;
