/* eslint-disable jsx-a11y/alt-text */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import FieldHeader from '../../components/FieldHeader';
import { useAppDispatch } from '../../store/hooks';
import { setEnableAuth } from './AuthSlice';
import { useLoginMutation } from '../appApi';
import { useNotifier } from '../../core/Notifier';
import { IAuth } from '../../core/Interface/api.interface';
import { theme } from '../../theme';
import { appColors } from '../../theme/appColors';

const Login = () => {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const { showErrorMessage } = useNotifier();
  const [login, { isLoading }] = useLoginMutation();
  //Form validation
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        const loginResponse = await login(values).unwrap();
        if (!loginResponse.status) {
          showErrorMessage(loginResponse.message);
        } else {
          const tokenData = loginResponse.data as IAuth;
          localStorage.setItem('token', tokenData.token);
          dispatch(setEnableAuth());
        }
      } catch (error) {
        showErrorMessage('Please check you username or password');
      }

      // navigate('/dashboard');
    },
  });
  const formValid = useMemo(() => {
    return formik.values.username === '' || formik.values.password === ''
      ? false
      : true;
  }, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        spacing={0}
        sx={{ minHeight: '100vh' }}
      >
        <Card
          sx={{
            backgroundColor: appColors.greenSmoke[20],
            minWidth: isSmall ? 200 : 400,
            pb: 8,
            pt: 8,
            pr: 4,
            pl: 4,
          }}
        >
          <CardContent sx={{}}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ fontSize: 20, fontWeight: 700, pb: 4, pt: 2 }}>
                {' '}
                Welcome Back
              </Typography>
            </Box>
            <Box>
              <FieldHeader title={'User Name'} />
              <TextField
                placeholder="Enter user name"
                size="small"
                sx={{ width: '100%' }}
                {...formik.getFieldProps('username')}
              />
            </Box>
            <Box sx={{ paddingTop: '12px' }}>
              <FieldHeader title={'Password'} />
              <TextField
                placeholder="Enter password"
                size="small"
                sx={{ width: '100%' }}
                {...formik.getFieldProps('password')}
                type="password"
              />
            </Box>
          </CardContent>
          <CardActions sx={{ minWidth: '40wh', padding: '16px' }}>
            <Button
              autoFocus
              disabled={!formValid || isLoading}
              fullWidth
              sx={{ marginBottom: '8px' }}
              type="submit"
              variant="contained"
            >
              {isLoading && (
                <Box sx={{ pr: 2, pt: 0.5 }}>
                  <CircularProgress
                    size={16}
                    sx={{ color: 'white' }}
                  />
                </Box>
              )}
              Login
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </form>
  );
};

export default React.memo(Login);
